.unauthorizedPageWrapper {
  height: 254px;
  width: 400px;
  margin: 50px auto;
  vertical-align: center;
  background-color: white;
  display: flex;
  border-radius: 4px;
  flex-direction: column;
}

.gradientAndLogo {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  height: 90px;
  background-image: linear-gradient(
    273deg,
    rgba(250, 165, 26, 1),
    rgba(240, 100, 36, 1),
    rgba(229, 32, 47, 1),
    rgba(158, 7, 174, 1)
  );
  text-align: center;
}

.logo {
  margin: 16px;
}

.mainText {
  padding: 12px 0 12px 24px;
  font-size: 21px;
  font-weight: 300;
}

.secondaryText {
  font-size: 12px;
  padding-left: 24px;
}

.button {
  margin: auto;
}